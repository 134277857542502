/* eslint-disable react/jsx-no-comment-textnodes */
import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'
import Layout from 'components/Layout'
import PressPackage from '../../components/press/PressPackage'
import Caption from 'components/press/Caption'
import EmbeddedLink from 'components/press/EmbeddedLink'
import ExternalLink from 'components/ExternalLink'
import Seo from '../Seo'
import { PressArticleQuery } from 'types'

type ArticlePostPageProps = {
  data: {
    mdx: {
      frontmatter: {
        title: string
        date: string
      }
      body: string
    }
    allMdx: {
      nodes: PressArticleQuery[]
    }
  }
}

// shortcodes for passing in components
const shortcodes = { Caption, EmbeddedLink }

const Blockquote = ({ children }: { children: JSX.Element }) => {
  return (
    <blockquote>
      <h4>{children}</h4>
    </blockquote>
  )
}

const Paragraph = ({ children }: { children: JSX.Element }) => {
  return <p>{children}</p>
}

const ArticlePostPage = ({ data }: ArticlePostPageProps) => {
  const post = data.mdx

  return (
    <Layout>
      {/* HERO */}
      <div className="pt-12.5 page-section inner-container flex flex-row flex-wrap justify-center text-center">
        <h1 className="mt-5">{post.frontmatter.title}</h1>
        <div className="inline-block mt-5 tag">{post.frontmatter.date}</div>
      </div>
      {/* Article */}
      <div className="px-5 blog-post mt-20">
        <MDXProvider
          components={{
            blockquote: Blockquote,
            a: ExternalLink,
            p: Paragraph,
            ...shortcodes,
          }}
        >
          <MDXRenderer>{post.body}</MDXRenderer>
        </MDXProvider>
        <hr className="final-hr" />
        <p className="mt-7.5">
          Published on <strong>{post.frontmatter.date}</strong>
        </p>
      </div>
      <PressPackage />
    </Layout>
  )
}

export const query = graphql`
  query ArticleQuery($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
      }
      body
    }
  }
`

export default ArticlePostPage

export const Head = ({ data }: ArticlePostPageProps) => {
  const post = data.mdx
  return (
    <Seo
      title={post.frontmatter.title}
      description={`${post.body
        .split('mdx("p", null, "')[1]
        .substring(0, 180) // Generate an SEO description from the first 180 characters of the article body.
        .replace(/\\u[\dA-F]{4}/gi, (unicode) => {
          // Parse unicode characters.
          return String.fromCharCode(parseInt(unicode.replace(/\\u/g, ''), 16))
        })}[...]`}
      image="press_hero_bg.jpg"
    />
  )
}
