import * as React from 'react'
import { skipNoReferrer } from '../../utils/url_helpers'

type EmbeddedLinkProps = {
  linkURL: string
  linkText: string
}

const EmbeddedLink = ({ linkURL, linkText }: EmbeddedLinkProps) => {
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      target="_blank"
      rel={`noopener ${skipNoReferrer(linkURL) ? '' : 'noreferrer'}`}
      className="underline"
      href={linkURL}
    >
      {linkText}
    </a>
  )
}

export default EmbeddedLink
