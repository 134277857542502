import * as React from 'react'

type CaptionProps = {
  children: React.ReactNode
}

const Caption = ({ children }: CaptionProps) => {
  return (
    <figcaption className={`text-sm text-grey-600 caption mb-12 mt-2`}>
      {children}
    </figcaption>
  )
}

export default Caption
